import {BI_ORIGINS, focusElement} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {DH, hookToAttributeSelector} from '../../constants/data-hooks'
import {Divider} from '../divider'
import {Members} from '../members'
import {SocialShareWithTitle} from '../social-share/with-title'
import {Subtitle} from '../subtitle'
import * as cs from '../classnames.scss'
import {AboutSection} from './about-section'
import {getAlignmentClass} from './alignment'
import * as s from './event-details.scss'
import {FullDate} from './full-date'
import {FullLocation} from './full-location'
import {Header} from './header'
import {EventDetailsProps} from './interfaces'
import {MembershipOffers} from './membership-offers'
import {RegistrationButton} from './registration-button'
import {TicketsPicker} from './tickets-picker'
import {Map} from './map'

export class EventDetails extends React.Component<EventDetailsProps> {
  componentDidMount() {
    this.props.detailsPageLoaded()
    focusElement(hookToAttributeSelector(DH.eventDetails))
  }

  handleRsvp = () => this.props.handleRSVP()

  render() {
    const {
      eventTitle,
      texts,
      contentAlignment,
      ticketedEvent,
      membersVisible,
      mapVisible,
      membersDividerVisible,
      aboutSectionVisible,
      showPaidPlans,
      t,
    } = this.props

    return (
      <section data-hook={DH.eventDetails} aria-label={eventTitle}>
        <div className={classNames(s.background, cs.evBackgroundColor)} />
        {/* TODO: header is not yet fully responsive (i.e. image) */}
        <Header onClickRegistrationButton={ticketedEvent ? scrollToTicketsPicker : this.handleRsvp} />
        <div className={s.container} data-hook={DH.eventDetailsContent}>
          <div className={classNames(s.content, getAlignmentClass(contentAlignment))}>
            <Subtitle dataHook={DH.subtitleTimeAndLocation} text={texts.timeAndLocationTitleText} />
            <div className={classNames(s.timeAndLocation, cs.evTextFont, cs.evTextColor)}>
              <FullDate />
              <FullLocation />
            </div>
            {membersVisible ? (
              <>
                <Subtitle dataHook={DH.MEMBERS_TITLE} text={texts.guestsTitleText} compact />
                <div className={s.membersContainer}>
                  <Members />
                </div>
                {membersDividerVisible && <Divider />}
              </>
            ) : null}
            {aboutSectionVisible && <AboutSection compact={membersVisible} />}
            {!ticketedEvent && <RegistrationButton onClick={this.handleRsvp} secondary />}
            {showPaidPlans && <MembershipOffers />}
            {ticketedEvent && <TicketsPicker t={t} />}
          </div>
          {mapVisible && (
            <div className={s.mapSpacing}>
              <Map />
            </div>
          )}
          <div className={classNames(s.content, s.sectionSpacing, getAlignmentClass(contentAlignment))}>
            <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
          </div>
          <div className={s.safariMarginFix} />
        </div>
      </section>
    )
  }
}

const scrollToTicketsPicker = () => {
  focusElement(hookToAttributeSelector(DH.TICKETS_PICKER))
}
