import * as classNames from 'classnames'
import * as React from 'react'
import StatusAlert from 'wix-ui-icons-common/StatusAlert'
import {Badge} from '../../../badge'
import * as c from '../../../classnames.scss'
import {QuantityPicker} from '../quantity-picker'
import * as s from './mobile-ticket.scss'
import {Price} from './price'
import {TicketDescription} from './ticket-description'
import {SoldOutProps, TicketProps, TooltipProps} from '.'

export const Ticket: React.FC<TicketProps> = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  t,
  isMobile,
  isHollowButton,
}) => {
  const {id, limitPerCheckout, name, description} = ticket
  const showTooltip = selectedQuantity > 0 && availableTicketsQuantity === 0

  return (
    <div key={id} className={classNames(s.ticket, c.evTextColor, c.evTextFont)} data-hook="ticket">
      {!limitPerCheckout ? <SoldOut t={t} /> : null}
      <div
        className={classNames(s.name, c.evTicketNameColor, c.evTicketNameFont)}
        data-hook="ticket-name"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </div>
      <Price t={t} ticket={ticket} />
      <div className={s.inner}>
        <div className={s.quantity}>
          <QuantityPicker
            quantity={selectedQuantity}
            min={0}
            max={Math.min(availableTicketsQuantity + selectedQuantity, limitPerCheckout)}
            disabled={!limitPerCheckout || registrationClosed}
            onChange={value => onChange(id, value)}
            t={t}
            isMobile={isMobile}
          />
        </div>
      </div>
      {showTooltip ? <Tooltip isHollowButton={isHollowButton} t={t} /> : null}
      {description ? (
        <div className={s.description}>
          <TicketDescription ticketDefId={id} description={description} />
        </div>
      ) : null}
      <div className={classNames(s.divider, c.evTicketDividerColor)} />
    </div>
  )
}

const Tooltip: React.FC<TooltipProps> = ({isHollowButton, t}) => {
  const className = isHollowButton ? c.readMoreHollowStrokeColor : c.readMoreStrokeColor
  return (
    <div className={s.info}>
      <div className={classNames(className, s.infoIcon)}>
        <StatusAlert size="20" />
      </div>
      <div className={s.infoMessage}>{t('mobile.quantityPicker.maximumAmount')}</div>
    </div>
  )
}

const SoldOut: React.FC<SoldOutProps> = ({t}) => (
  <div className={s.soldOut}>
    <Badge>{t('mobile.ticketsPicker.soldOut')}</Badge>
  </div>
)
