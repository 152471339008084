import * as React from 'react'
import {DH} from '../../../constants/data-hooks'
import {Columns} from './columns'
import {Footer} from './footer'
import {Header} from './header'
import * as s from './tickets-picker.scss'
import {TicketsPickerProps} from './index'

export class TicketsPicker extends React.Component<TicketsPickerProps> {
  componentDidMount() {
    this.props.clearInvoice()
    this.props.clearCheckout()
  }

  componentDidUpdate() {
    if (this.props.reservationUnavailable && !this.props.isEditor) {
      this.props.openCheckoutUnavailable()
    }
  }

  render() {
    const {tickets, t, title} = this.props

    if (!tickets.length) {
      return null
    }

    return (
      <div role="table" className={s.ticketsPicker} data-hook={DH.TICKETS_PICKER}>
        <Header title={title} t={t} />
        <Columns t={t} />
        <Footer t={t} />
      </div>
    )
  }
}
